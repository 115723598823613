* {
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

*::-moz-focus-inner {
  border: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 40rem #ffffff inset;
}

select, input, textarea {
  font-size: 99%;
}

body {
  font-family: Arial, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
  font-size: 100.01%;
  color: #cdcdcd;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%239C92AC' fill-opacity='0.10' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E"), linear-gradient(to right top, #000000, #151314, #211f22, #2b2c31, #211f22, #151314, #000000);
  height: 100vh;
  max-height: -webkit-fill-available;
  touch-action: pan-x pan-y;
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.t-center {
  text-align: center !important;
}

h1, h2 {
  color: #cdcdcd;
  font-size: 28px;
  letter-spacing: 1px;
  margin: 0;
}

p {
  font-size: 16px;
  line-height: 22px;
  margin: 10px 30px 20px;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  20%, 80% {
    opacity: 1;
  }
}
@keyframes changeColor {
  0%, 100% {
    background: rgba(0, 0, 0, 0.3);
  }
  30%, 70% {
    background: rgba(139, 139, 139, 0.3);
  }
}
.pincode {
  position: relative;
}
.pincode h1 {
  margin-bottom: 0;
  font-size: 30px;
  text-transform: uppercase;
}
.pincode p.error {
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 20px 0 0;
  font-size: 16px;
  opacity: 0;
  color: #d60202;
  animation: fadeInOut 2s normal;
}
.pincode .inputs {
  color: #cdcdcd;
  font-size: 100px;
  line-height: 20px;
  margin: 5px 0 40px;
  height: 20px;
  display: flex;
  align-content: center;
  justify-content: center;
}
.pincode .inputs div {
  background: #43454b;
  width: 20px;
  height: 5px;
  border-radius: 3px;
  margin: 0 5px;
  transition: all 0.3s;
}
.pincode .inputs div.active {
  background: #cdcdcd;
}
.pincode .inputfield {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
}
.pincode .inputfield .input {
  flex: 1 0 100px;
  margin-bottom: 20px;
}
.pincode .inputfield .input button {
  width: 80px;
  height: 80px;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #cdcdcd;
  border-radius: 25px;
  color: #cdcdcd;
  font-size: 30px;
}
.pincode .inputfield .input button.clicked {
  animation: changeColor 0.5s normal;
}